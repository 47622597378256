.swiper-pagination {
  @apply mb-14 space-x-4 md:mb-20 md:space-x-5 !important;
}

.swiper-pagination-bullet {
  @apply wh-2 bg-white opacity-30 md:wh-3 !important;
}

.swiper-pagination-bullet-active {
  @apply bg-white opacity-100 !important;
}
