.react-calendar {
  padding-bottom: 28px;
}

.react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation__label {
  font-size: 1.25rem;
  font-weight: 600;
}

.react-calendar__navigation__prev-button {
  padding: 0 8px;
  margin-right: 16px;
  font-size: 2.25rem;
}

.react-calendar__navigation__next-button {
  padding: 0 8px;
  margin-left: 16px;
  font-size: 2.25rem;
}

.react-calendar__month-view__weekdays__weekday {
  color: #6d7684;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar__viewContainer {
  margin-top: 1rem;
}

.react-calendar__month-view__days__day {
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.react-calendar__tile--now {
  font-weight: bold;
}

.react-calendar__tile--active > abbr {
  position: relative;
  border-radius: 100%;
  color: white;
  z-index: 0;
}
.react-calendar__tile--active > abbr::before {
  content: '';
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #0b62e3;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: -1;
}
